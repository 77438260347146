import React from 'react'
import SocialLinks from '../components/sociallinks/SocialLinks'
import NavBar from '../components/navbar/NavBar'
import Footer from '../components/footer/Footer'
import DocBox from '../components/docbox/DocBox'

const Documents = () => {
    return (
        <>
            {/* <SocialLinks /> */}
            <NavBar />
            <div className="container">
                <div className="comming-soon">
                    <DocBox />
                </div>
            </div>
            <Footer />

        </>
    )
}

export default Documents
