import DefaultLayout from '../layout/DefaultLayout';
import Home from '../screens/home'
import HowtoBuy from '../screens/HowtoBuy'
import Media from '../screens/media'
import Team from '../screens/team'
import Document from '../screens/documents';
import LoadingScreen from '../screens/loading';
import Marketing from '../screens/marketing';
import FaqScreen from '../screens/faqscreen';
import Charity from '../screens/charity';
import LightPaper from '../screens/lightpaper';


const routes = [
    {
        path: `/`,
        exact: true,
        layout: DefaultLayout,
        component: Document,
    },
    {
        path: `/home`,
        exact: true,
        layout: DefaultLayout,
        component: Document,
    },
    // {
    //     path: `/howtobuy`,
    //     exact: true,
    //     layout: DefaultLayout,
    //     component: HowtoBuy,
    // },
    // {
    //     path: `/media`,
    //     exact: true,
    //     layout: DefaultLayout,
    //     component: Media,
    // },
    // {
    //     path: `/team`,
    //     exact: true,
    //     layout: DefaultLayout,
    //     component: Team,
    // },
    // {
    //     path: `/documents-audits`,
    //     exact: true,
    //     layout: DefaultLayout,
    //     component: Document,
    // },
    // {
    //     path: `/loading`,
    //     exact: true,
    //     layout: DefaultLayout,
    //     component: LoadingScreen,
    // },
    // {
    //     path: `/marketing`,
    //     exact: true,
    //     layout: DefaultLayout,
    //     component: Marketing,
    // },

    // {
    //     path: `/faq`,
    //     exact: true,
    //     layout: DefaultLayout,
    //     component: FaqScreen,
    // },
    // {
    //     path: `/charity`,
    //     exact: true,
    //     layout: DefaultLayout,
    //     component: Charity,
    // },
    // {
    //     path: `/litepaper`,
    //     exact: true,
    //     layout: DefaultLayout,
    //     component: LightPaper,
    // }
]


export default routes;