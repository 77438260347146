import React from 'react'
import MainImage from '../../assets/ast/ast-02.png'
import Instructions from '../../assets/signup/instructions.jpeg'
import LogoText from '../../assets/logo-text.png'
import Migration01 from '../../assets/migration-01.jpg'
import Migration02 from '../../assets/migration-02.jpg'
import Migration03 from '../../assets/migration-03.jpg'



const DocBox = () => {
    return (
        <div>
            <div className="new-container">
                <div className="main-doc-box-container">
                    <div className="logo-container">
                        {/* <img src={LogoText} alt="" />
                        <div className="sign-up-div">
                            <a href="#"><img src={SignUp} alt="" /></a>
                        </div> */}
                        <div className="container">
                            <div className="row">
                                <div className="col-md-offset-2 col-md-8">
                                    <img src={Migration01} alt="" className='img-responsive' />
                                    <img src={Migration02} alt="" className='img-responsive' />
                                    <img src={Migration03} alt="" className='img-responsive' />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DocBox
