import React, { useEffect, useState } from 'react'
import { getServerSideProps } from '../components/playlist/PlayList'
import SocialLinks from '../components/sociallinks/SocialLinks'
import NavBar from '../components/navbar/NavBar'
import Footer from '../components/footer/Footer'
import ScrollTop from '../components/scrolltop/ScrollTop'

const Media = () => {

    const [playList, setPlayList] = useState([])

    useEffect(async () => {
        let data = await getServerSideProps();
        console.log(data)
        setPlayList(data.props.data.items)
    }, [])

    return (
        <>
            <SocialLinks />
            <NavBar />

            <div className="container">
                <div className="main-video-container">
                    <h1>Videos</h1>
                    <div className="col-md-12">
                        <div className="main-video">
                            <iframe width="100%" height="400" src="https://www.youtube.com/embed/PJ_aU9KaEQc?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                    </div>

                    {playList.map(({ id, snippet = {} }) => {
                        const { title, thumbnails = {}, resourceId = {} } = snippet;
                        const { medium } = thumbnails;

                        if (medium?.url) {
                            return (
                                <div className="video-card">
                                    <span key={id}>
                                        <a target="_blank" href={`https://www.youtube.com/watch?v=${resourceId.videoId}`}>
                                            <p>
                                                <img width={medium?.width} height={medium?.height} src={medium.url} alt="" />
                                            </p>
                                            <h3>{title}</h3>
                                        </a>
                                    </span>
                                </div>
                            )
                        }

                    })}
                </div>
            </div>
            <ScrollTop />
            <Footer />
        </>
    )
}

export default Media
