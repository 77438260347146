import React from 'react'
import AOS from "aos";
import Safe from '../../assets/safe.svg'
import Automatic from '../../assets/automatic.svg'
import Coins from '../../assets/coins.svg'
import Comunity from '../../assets/comunity.svg'

import "aos/dist/aos.css";

AOS.init();

const MainImages = () => {
    return (
        <div className="container">
            <div className="row">
                <div className="col-md-10 col-md-offset-1">
                    <div className="main-images">
                        <div className="image-container"
                            data-aos="fade-right"
                            data-aos-delay="50"
                            data-aos-duration="2000"
                            data-aos-easing="ease-in-out-cubic"
                        >
                            <img src={Safe} alt="" />
                            <h3>100% Safe</h3>
                            <p>Ownership renounced. Dev burned all LP tokens and participated in the fair launch with everyone else.</p>
                        </div>
                        <div className="image-container"
                            data-aos="fade-left"
                            data-aos-delay="50"
                            data-aos-duration="2000"
                            data-aos-easing="ease-in-out-cubic"
                        >
                            <img src={Automatic} alt="" />
                            <h3>Automatic LP</h3>
                            <p>Automatic LP is the 2% of the 4% Slippage from the Pancake Swap; Every Transaction Buy/Sell/Transfers
                                has 2% Automatic LP, that goes back to Liquidity.</p>
                        </div>
                        <div className="image-container"
                            data-aos="fade-right"
                            data-aos-delay="50"
                            data-aos-duration="2000"
                            data-aos-easing="ease-in-out-cubic"
                        >
                            <img src={Comunity} alt="" />
                            <h3>Community Owned</h3>
                            <p>Community driven, fair-launched and without presale. The dev added 100% of the token supply to the LP on PancakeSwap and participated in the fair-launch with everyone else/ They are equal to all other investors in $PIZZA and renounced their ownership, so it is 100% rugproof.</p>
                        </div>
                        <div className="image-container"
                            data-aos="fade-left"
                            data-aos-delay="50"
                            data-aos-duration="2000"
                            data-aos-easing="ease-in-out-cubic"
                        >
                            <img src={Coins} alt="" />
                            <h3>Passive Rewards</h3>
                            <p>Earn rewards just for holding $PIZZA in your wallet! 2% from the pancake swap 4% slippage and from every transaction is distributed to existing Safepizza holders.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MainImages
